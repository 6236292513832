.toast {
  position: fixed;
  left: 50%;
  top: -5%;
  transform: translate(-50%, -50%);
  width: 320px;
  background-color: var(--light-white);
  color: var(--neutral-black);
  padding: 1rem;
  border-radius: 10px;
  box-shadow: var(--border-gray) 0px 5px 15px;
  display: flex;
  transition: top ease-in 0.3s;
  z-index: 2000;
}

.toast.open {
  top: 5%;
}

.toast.success {
  border-left: 5px solid var(--success-color);
}

.toast.error {
  border-left: 5px solid var(--error-color);
}

/* icons */
.fa-solid.fa-circle-check {
  display: none;
}

.fa-solid.fa-circle-exclamation {
  display: none;
}

.toast.success .fa-solid.fa-circle-check {
  display: block;
  color: var(--success-color);
}

.toast.error .fa-solid.fa-circle-exclamation {
  display: block;
  color: var(--error-color);
}

.toast_msg {
  margin-left: 6px;
}
