.form_group {
  position: relative;
}

.form_label {
  display: block;
  margin: 0.7rem 0;
}

.form_input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--neutral-white);
  background-color: transparent;
  border-radius: 7px;
  font-family: inherit;
  color: var(--neutral-white);
}

.form_input:focus {
  outline: none;
}

/* view password btn */
.view_password {
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: var(--neutral-white);
}

.sign_in_footer {
  padding: 20px 0;
  border-top: 1px solid var(--border-gray);
}
