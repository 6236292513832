.sign_up {
  max-width: 500px;
  padding: 2rem;
}

/* form validation */
.form_group.success .form_input {
  border-color: var(--success-color);
}

.form_group.error .form_input {
  border-color: var(--error-color);
}

.form_group small {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
}

.form_group.error small {
  position: relative;
  color: var(--error-color);
  visibility: visible;
  /* padding-top: 15px; */
}
