.footer {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--border-gray);
}

.footer_left img {
  width: 70%;
}
