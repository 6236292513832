.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal_container {
  position: fixed;
  bottom: 0;
  background-color: var(--neutral-black);
  padding: 20px 50px;
  width: 50vw;
  border-radius: 1rem 1rem 0 0;
  z-index: 1000;
  animation: slidein 0.7s ease-in-out;
}

/* cross btn */
.cross_btn {
  padding-right: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.cross_btn img {
  width: 18px;
  cursor: pointer;
}

.cross_btn img:hover {
  transform: scale(0.9);
}

/* drag and drop section */
.drag_area {
  width: 100%;
  height: 300px;
  padding: 1rem;
  border: 2px dotted var(--neutral-white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.drag_area.active {
  border: 2px dotted var(--success-color);
}

.error-msg {
  color: var(--error-color);
  border: 2px dotted var(--error-color);
}

/* image preview */
.preview {
  margin: 20px 0;
  padding: 1rem;
  background-color: var(--border-gray);
  border-radius: 0.7rem;
}

.preview img {
  width: 100px;
}

.preview_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.preview_body_right button {
  border: none;
  background: transparent;
  cursor: pointer;
}

#delete_icon {
  width: 40px;
  transition: all 0.5s ease-in-out;
}

.preview_body_right button:hover #delete_icon {
  transform: scale(0.93);
}

/* keyframes */
@keyframes slidein {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

/* responsive */
@media screen and (max-width: 900px) {
  .modal_container {
    width: 100vw;
  }
}
