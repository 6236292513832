nav {
  background: var(--neutral-black);
  z-index: 1000;
  top: 0;
  right: 0;
  padding: 20px;
  padding-top: 90px;
  height: 100%;
  box-sizing: border-box;
  width: 25%;
  right: -25%;
  position: fixed;
  transition: right ease-in 0.3s;
}

nav.open {
  right: 0;
}

/* burger */
.burger {
  margin: 30px 0;
  padding: 1rem;
  z-index: 1000;
  cursor: pointer;
}

.burger span {
  display: block;
  width: 30px;
  height: 2px;
  background-color: var(--neutral-white);
  margin-bottom: 5px;
  transition: all ease-in 0.3s;
}

/* open state */
.burger.open span:nth-child(1) {
  transform: rotate(45deg) translateY(10px);
}
.burger.open span:nth-child(2) {
  opacity: 0;
  width: 0;
}
.burger.open span:nth-child(3) {
  transform: rotate(-45deg) translateY(-10px);
}

/* nav links */
.nav_links {
  margin: 2rem;
  padding: 1rem;
}

.nav_links li {
  list-style: none;
  margin: 2rem 0;
  cursor: pointer;
  font-size: 1.3rem;
  /* width: 115px; */
}

.nav_links li:hover {
  border-bottom: 1px solid var(--neutral-white);
}

/* responsive */
@media screen and (max-width: 1200px) {
  nav {
    width: 40%;
    right: -40%;
  }
}

@media screen and (max-width: 600px) {
  nav {
    width: 70%;
    right: -70%;
  }
}

@media screen and (max-width: 500px) {
  .nav_links {
    margin-left: 0;
    margin-right: 0;
  }
}
