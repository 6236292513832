/* .skeleton_wrapper {
  margin-top: 60px;
} */

.skeleton {
  opacity: 0.7;
  background-color: rgba(66, 70, 77, 0.1);
  border-radius: 5px;
  animation: loading 1.5s linear infinite alternate;
}

.skeleton.circle {
  border-radius: 50%;
}

/* keyframes animation */
@keyframes loading {
  0% {
    background-color: rgba(66, 70, 77, 0.3);
  }

  /* 50% {
    transform: translateX(-60%);
  } */

  100% {
    background-color: rgba(66, 70, 77, 0.7);
  }
}
