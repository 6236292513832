.activities_cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  min-height: 60vh;
}

/* cards */
.activities_card {
  width: 200px;
  height: 300px;
  margin: 1rem;
  border: 1px solid var(--border-gray);
  padding: 10px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.activities_card img {
  width: 100%;
  height: 200px;
}

.curation_status {
  display: flex;
  justify-content: space-between;
}

.activities_card small {
  color: var(--border-gray);
}

/* responsive */
@media screen and (max-width: 500px) {
  .activities_card {
    width: 100%;
  }
}
