@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");

:root {
  --neutral-black: #22272e;
  /* --neutral-black: #1a1a1a; */
  --neutral-white: #fff;
  --light-yellow: #fca311;
  --light_gray: #e5e5e5;
  --border-gray: rgba(84, 84, 84, 0.48);
  --light-white: #f1f6fe;

  --success-color: #2ecc71;
  --error-color: #e74e3c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--neutral-black);
  font-family: "Montserrat", sans-serif;
  color: var(--neutral-white);
}

/* custom scrollbar */
html {
  scrollbar-width: 0.5vw;
  scrollbar-color: #777 var(--neutral-black);
}

html::-webkit-scrollbar {
  width: 0.5vw;
}

html::-webkit-scrollbar-thumb {
  background-color: #777;
  border-radius: 5px;
}

/* utils */
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.my-1 {
  margin: 1rem 0;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding: 0 1rem;
}

.text-center {
  text-align: center;
}

/* with 100% */
.w-100 {
  width: 100%;
}

.btn {
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: large;
  border-radius: 0.7rem;
  cursor: pointer;
  background-color: var(--light-white);
  transition: all 0.4s ease-in;
}

.btn:hover {
  transform: translateY(2px);
}

.btn:disabled,
.btn[disabled] {
  background-color: rgba(46, 204, 113, 0.4);
  color: var(--neutral-white);
  cursor: not-allowed;
}

.btn-green {
  width: 100%;
  color: var(--neutral-white);
  background-color: var(--success-color);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex {
  display: flex;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.align-item-center {
  align-items: center;
}

.gap-10 {
  gap: 10px;
}

.s-msg {
  padding: 0.5rem;
  margin: 10px 0;
  color: var(--success-color);
  background-color: rgba(46, 204, 113, 0.16);
  border-radius: 10px;
  border: 1px solid var(--success-color);
}

/* background warning */
.bg-success {
  padding: 1px 3px;
  background-color: rgba(46, 204, 113, 0.16);
  border: 1px solid var(--success-color);
  color: var(--success-color);
  border-radius: 7px;
  font-size: 10px;
}

.bg-warning {
  padding: 1px 3px;
  background-color: rgba(253, 236, 208, 0.2);
  border: 1px solid var(--light-yellow);
  color: var(--light-yellow);
  border-radius: 7px;
  font-size: 10px;
}

.bg-err {
  padding: 4px;
  background-color: rgba(231, 78, 60, 0.28);
  border: 1px solid var(--error-color);
  border-radius: 7px;
}

a {
  color: var(--light-white);
}

/* colors */
.color-s {
  color: var(--success-color);
}

.color-w {
  color: var(--light-yellow);
}

.color-d {
  color: var(--error-color);
}

.color-gray {
  color: var(--border-gray);
}
