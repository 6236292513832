.profile_info {
  display: flex;
  align-items: center;
}

/* responsive */
@media screen and (max-width: 500px) {
  .profile_info {
    flex-direction: column;
    align-items: flex-start;
  }
}
