.admin_card {
  width: 100%;
  height: 300px;
  display: flex;
  gap: 20px;

  border: 1px solid var(--border-gray);
  border-radius: 10px;
  padding: 1rem;
  margin: 25px 0;
}
.admin_card_img {
  width: 300px;
}

.admin_card img {
  max-width: 100%;
  max-height: 100%;
}

.card_details {
  width: 100%;
}

.admin_curation_status {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* responsive */
@media screen and (max-width: 750px) {
  .admin_card {
    flex-direction: column;
    height: 100%;
  }

  .admin_card_img {
    height: 300px;
  }
}
