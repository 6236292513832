/* header section */
header {
  padding: 2rem;
  margin-top: 60px;
}

.header-title {
  background: #a770ef; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #fdb99b,
    #cf8bf3,
    #a770ef
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #fdb99b,
    #cf8bf3,
    #a770ef
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-clip: text;
  -webkit-text-fill-color: transparent;

  margin-top: 3rem;
  font-size: 4rem;
}

/* main section */
.features {
  padding: 1rem;
  margin: 50px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.card {
  text-align: center;
  padding: 10px;
}

.card p {
  margin-top: 10px;
}

/* responsive */
@media screen and (max-width: 500px) {
  .header-title {
    font-size: 3rem;
  }
}
