.activity_details {
  display: flex;
  justify-content: space-around;
}

/* responsive */
@media screen and (max-width: 1000px) {
  .activity_details {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .activity_details > :nth-child(2) {
    display: none;
  }
}
