/* avatar */
.avatar {
  margin: 5px;
}

.avatar img {
  width: 40px;
  background-color: var(--neutral-white);
  border-radius: 50%;
  cursor: pointer;
}

.avatar.xl img {
  width: 150px;
}
